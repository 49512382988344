export interface ProjectCreateRequest {
  locationId: string;
  projectName: string;
  projectScope: string;
  projectTypeId: string;
  projectCategoryId: string;
  projectPriorityId: string;
  projectSourceId: string;
  plannerUserId: string;
  implementationTypeId: string;
  categoryId: string;
  subcategoryId: string;
  projectMofId: string;
  comment: string;
  arBatchMonthId: string;
  arPriority: number;
  capitalProjectNumber: string;
  fmpgNumber: string;
  unitId: string;
  csRequiredId?: string;
  designFundingOnly: true;
  equipmentNeeded: true;
  projectSupplementingJobId: string;
  projectSupplementingComment: string;
  projectEstimatedPlans: number;
  projectEstimatedLand: number;
  projectEstimatedDesign: number;
  projectEstimatedConstruction: number;
  projectEstimatedEquipment: number;
  cdBfy: string;
  cdAct: string;
  cdItem: string;
  appropriationItemTotal: number;
  legislativeTitle: string;
  legislativeDescription: string;
  appropriationGroupId: string;
}
