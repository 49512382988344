export interface ProjectUpdateRequest {
  locationId: string;
  projectName: string;
  projectScope: string;
  projectTypeId?: string;
  projectCategoryId: string;
  projectPriorityId: string;
  projectSourceId: string;
  plannerUserId: string;
  implementationTypeId: string;
  categoryId: string;
  subcategoryId: string;
  projectMofId: string;
  comment: string;
  arBatchMonthId: string;
  arPriority: number;
  capitalProjectNumber: string;
  fmpgNumber: string;
  unitId: string;
  csRequiredId?: string;
  designFundingOnly: boolean;
  equipmentNeeded: boolean;
  projectSupplementingJobId: string;
  projectSupplementingComment: string;
  projectEstimatedPlans: number;
  projectEstimatedLand: number;
  projectEstimatedDesign: number;
  projectEstimatedConstruction: number;
  projectEstimatedEquipment: number;
  cdBfy: string;
  cdAct: string;
  cdItem: string;
  appropriationItemTotal: number;
  legislativeTitle: string;
  legislativeDescription: string;
  appropriationGroupId: string;
  statusVoidReasonId: string;
  statusVoidReasonComment: string;
  projectMaximo?: {
    maximoWorkOrderNumber: string;
    maximoBuilding: string;
    maximoRoom: string;
    planYearFy: string;
    uniformatLevelId: string;
    famProjectScoreId: string;
    famProjectPriorityId: string;
  };
  projectStatusId: string;
}
